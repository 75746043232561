import { graphql } from "gatsby"

import React, { useEffect } from "react"
import { FaSearch } from "react-icons/fa"
import { error404ContentWrapper, searchContainer } from "./404.module.css"

/* Components */
import Seo from "../components/seo/seo"
import Layout from "../components/layout"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import axios from "axios"

const NotFoundPage = ({ data }) => {
  const desks = data.allContentfulDesk

  useEffect(() => {
    async function fetchData() {
      const { availHeight, availWidth, height, width } = window.screen
      try {
        await axios.post(
          "https://europe-west1-mkb-servicedesk.cloudfunctions.net/botLogger-onHttpCallCreateLog",
          {
            data: {
              screen: { availHeight, availWidth, height, width },
              trafficHistory: window.locations,
            },
          }
        )
      } catch (error) {
        console.log(error)
      }
    }

    fetchData()
  }, [])

  return (
    <Layout>
      <Header desks={desks} />
      <main className="main">
        <div className="container">
          <Seo title="404: Pagina niet gevonden" />
          <div className={error404ContentWrapper}>
            <h1>404 error</h1>
            <p>
              Oeps.... er is iets verkeerd gegaan. De pagina die je zoekt kunnen
              we niet vinden of heeft nooit bestaan.
            </p>
            <h2>Zoeken</h2>
            <div className={searchContainer}>
              <form action="/zoeken">
                <input
                  aria-label="Stel je ondernemersvraag"
                  type="text"
                  placeholder="Stel je ondernemersvraag"
                  name="q"
                  className="placeholder-color"
                />
                <button aria-label="Verstuur je zoekopdracht" type="submit">
                  <FaSearch />
                </button>
              </form>
            </div>
            <p className="homepagelink">
              Of klik{" "}
              <a href="/" title="MKB Servicedesk Homepage">
                hier
              </a>{" "}
              om naar de homepage te gaan.
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    allContentfulDesk(sort: { fields: name }) {
      nodes {
        name
        slug
        slug2
        legacyId
        sortNavigation
      }
    }
  }
`
